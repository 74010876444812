html {
  box-sizing: border-box;
  font-size: 16px;
  min-height: -webkit-fill-available;
  display:flex;
  width: 100vw;
  flex-direction: column;
}
body {
  flex-grow: 1;
  display: flex;
}

#root {
  height: inherit;
  display: flex;
  flex-direction:column;
  width: 100%;
  flex-grow: 1;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}
.App {
  text-align: center;
  background: linear-gradient(180deg, #217CC0 0%, #74CCF2 85.5%);
  font-family: 'Staatliches';
  color: white;
  word-break: break-all;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-wrapper {
  width: 116px;
}

